import React, { useState, useEffect } from 'react';
import Table from '../../components/table/Table';
import View from '../../components/view/View';
import { Button, makeStyles, Typography, Paper } from '@material-ui/core';
import { trackClassMissionFullReportView } from '../../redux/actions/mixpanel-actions';
import { ChevronLeft } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { fetchApi } from '../../redux/actions/fetch-actions';
import { useDispatch, useSelector } from 'react-redux';
import StarsIcon from '@material-ui/icons/Stars';
import moment from 'moment';
import { hide as hideModal, show as showModal } from 'redux-modal';
import { APIMethods, APIEndpoints } from '../../types/fetch/fetch-types';
import {
  MODAL_CLASS_MISSION_REPORT,
  MODAL_NONPREMIUM_CLASS_MISSION
} from '../../constants/modals';
import { useIsUserPremium } from './ClassMissionPlayView';
import { useAppSelector } from '../../redux/hooks';

const useStyles = makeStyles(theme => ({
  icon: {
    fill: '#f8bb00',
    paddingRight: '35px'
  },
  result_loss: {
    color: '#FF0000'
  },
  result_win: {
    color: '#50C878'
  },
  report_premium_box: {
    border: '2px solid #3FD2D9',
    paddingLeft: '35px',
    paddingRight: '35px',
    width: '95px',
    height: '40px',
    backgroundColor: '#3FD2D9',
    '&:hover': {
      backgroundColor: '#3FD2D9'
    }
  },
  report_box: {
    border: '2px solid #3FD2D9',
    paddingLeft: '35px',
    paddingRight: '35px',
    width: '95px',
    height: '40px'
  },
  premium_text: {
    color: '#FFFFFF',
    fontWeight: 'bold'
  },
  text: {
    color: '#89CFF0',
    fontWeight: 'bold'
  },
  contentWrapper: {
    padding: 35,
    width: '100%'
  },
  date: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '10px',
    height: '25px'
  },
  heading: {
    fontSize: '20px'
  },
  reportMenuheading: {
    fontSize: '38px',
    paddingBottom: '18px',
    fontWeight: 'bold'
  }
}));

interface ClassMissionReportI {
  classMissionId: number;
  date: string;
  difficulty: string;
  setDuration: number;
  missionDuration: number;
  result: string;
}

type ClassMissionsReportViewProps = {
  flag?: Boolean;
};

const ClassMissionReportView: React.FC<ClassMissionsReportViewProps> = (
  props: ClassMissionsReportViewProps
) => {
  const styles = useStyles();
  const token = useAppSelector((state: any) => state.login.token);
  const features = useAppSelector(state => state.featureFlag);
  const userData = useSelector((state: any) => state.userData);
  const classData = useSelector((state: any) => state.class);
  const { flag } = props;
  const [classMissionReports, setClassMissionReports] = useState<
    ClassMissionReportI[]
  >([]);
  const [open, setOpen] = useState(false);
  const params = useParams<{ classCode: string }>();
  const isPremiumUser = useIsUserPremium(token, features, userData);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (params.classCode || classData.currentClass.code) {
      fetchClassMissionReportByClassCode(
        params.classCode || classData.currentClass.code
      );
    }
  }, [params.classCode]);

  async function fetchClassMissionReportByClassCode(classCode: string) {
    try {
      const response = await dispatch(
        fetchApi({
          url: `class-missions/report/${classCode}`,
          endpoint: APIEndpoints.EDUCATION,
          method: APIMethods.GET
        })
      );
      const { data } = response.data;
      if (Array.isArray(data)) {
        setClassMissionReports(data);
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  const handleClickPremiumOpen = (data: any) => {
    setOpen(true);
    const formattedDate = moment(data.date).format('MMMM DD, YYYY');
    const sendProps: any = {
      type: MODAL_CLASS_MISSION_REPORT,
      currentClassMissionId: data.classMissionId,
      date: formattedDate
    };
    dispatch(showModal('modal', sendProps));
    if (!userData.premium.isPremium) {
      trackClassMissionFullReportView('Free');
    } else if (userData.premium.type == 'Premium') {
      trackClassMissionFullReportView(userData.premium.type);
    } else {
      trackClassMissionFullReportView(
        userData.premium.type.concat(userData.premium.totalMemberships)
      );
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    const sendProps: any = {
      type: MODAL_NONPREMIUM_CLASS_MISSION
    };
    dispatch(showModal('modal', sendProps));
    trackClassMissionFullReportView('Free');
  };

  return (
    <View
      renderBackButton={() =>
        flag ? (
          <p className={styles.heading}>Class Mission Reports</p>
        ) : (
          <Button onClick={() => history.goBack()} style={{ marginRight: 10 }}>
            <ChevronLeft /> Back
          </Button>
        )
      }
    >
      {flag ? (
        ''
      ) : (
        <p className={styles.reportMenuheading}>Class Mission Reports</p>
      )}
      <>
        <Paper className={styles.contentWrapper}>
          {!classMissionReports.length ? (
            <Typography component="p">
              <i>Play Class Missions to generate reports.</i>
            </Typography>
          ) : (
            <Table
              columns={[
                {
                  Header: 'Date',
                  accessor: 'date',
                  Cell: (props: { value: string }) => {
                    const formattedDate = moment(props.value).format(
                      'MMMM DD, YYYY'
                    );
                    return (
                      <span className={styles.date}> {formattedDate}</span>
                    );
                  }
                },
                {
                  Header: 'Difficulty',
                  accessor: 'difficulty'
                },
                {
                  Header: 'Set Duration',
                  accessor: 'setDuration',
                  Cell: (props: { value: number }) => {
                    return <span>{props.value + 'm'}</span>;
                  }
                },
                {
                  Header: 'Mission Duration',
                  accessor: 'missionDuration',
                  Cell: (props: any) => {
                    const data = props.row.original;
                    const timeValue = props.value.toString();
                    const time = timeValue.split('.');
                    if (timeValue.includes('.')) {
                      if (data.setDuration > props.value) {
                        return <span>{time[0] + 'm ' + time[1] + 's'}</span>;
                      }
                      return <span>{data.setDuration + 'm'}</span>;
                    } else {
                      return <span>{props.value + 's'}</span>;
                    }
                  }
                },
                {
                  Header: 'Result',
                  accessor: 'result',
                  Cell: (row: any) =>
                    row.value == 'Loss' ? (
                      <b className={styles.result_loss}>Loss</b>
                    ) : (
                      <b className={styles.result_win}>Win</b>
                    )
                },
                {
                  Header: 'Full Report',
                  accessor: 'fullreport',
                  Cell: (row: any) => (
                    <div>
                      {isPremiumUser ? (
                        <>
                          <Button
                            className={styles.report_premium_box}
                            onClick={() => {
                              const data = row.row.original;
                              handleClickPremiumOpen(data);
                            }}
                          >
                            <p className={styles.premium_text}>View</p>
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            className={styles.report_box}
                            onClick={() => {
                              handleClickOpen();
                            }}
                          >
                            <StarsIcon className={styles.icon}></StarsIcon>
                            <p className={styles.text}>View</p>
                          </Button>
                        </>
                      )}
                    </div>
                  )
                }
              ]}
              data={classMissionReports}
            />
          )}
        </Paper>
      </>
    </View>
  );
};

export default ClassMissionReportView;
