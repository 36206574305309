import {
  Box,
  Grid,
  Link,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import { Help } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { hide, show } from 'redux-modal';
import screenshot from '../../assets/missions/missions_screenshot.png';
import Button from '../../components/button/Button';
import { MODAL_DEFAULT } from '../../constants/modals';
import { teacherRoutes } from '../../constants/routes';
import useQueryParams from '../../hooks/UseQueryParams';
import {
  userNavigateWithClassCode,
  userNavigateWithParam
} from '../../utils/user-route-getter';
import {
  classMissionUpgradeButtonClicked,
  trackClassMissionLaunched,
  trackNavBarClassMissionsItemClicked
} from '../../redux/actions/mixpanel-actions';
import TeacherFeatureFlagContext from '../../layouts/teacher/TeacherFeatureFlagContext';
import StarsIcon from '@material-ui/icons/Stars';
// import {
//   hasUserSeenPlacementTestWarningModal,
//   setPlacementTestWarningModalSeen
// } from '../../utils/user';
import { Link as RouterLink } from 'react-router-dom';
import colors from '../../styles/colors';
import Axios from 'axios';
import { useAppSelector } from '../../redux/hooks';
import { User } from '../../types/user/user-type';

const useStyles = makeStyles({
  title: {
    width: '731px',
    height: '28px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '28px'
  },
  button: {
    marginRight: 10,
    marginTop: 40,
    textAlign: 'center'
  },
  buttonMobile: {
    textAlign: 'center',
    marginTop: 20,
    marginRight: 10
  },
  paper: {
    padding: 20,
    width: '100%'
  }
});

interface UserSubscription {
  userId?: string;
  status: string;
}
const getUserSubscriptionsUsingAMApi = async (
  token: string
): Promise<UserSubscription[]> => {
  try {
    console.log(process.env);
    const updatedToken = token.replace('JWT', 'Bearer');
    const data = await Axios.get<UserSubscription[]>(
      `${process.env.REACT_APP_ACCOUNT_MANAGEMENT_API_URL}/v1/subscriptions/user`,
      {
        method: 'GET',
        headers: {
          'x-authorization': updatedToken
        }
      }
    );
    const userSubscriptions = data.data;

    console.log('AM API user subscriptions: ', { userSubscriptions });
    return userSubscriptions;
  } catch (err) {
    console.error('AM API user subscriptions error: ', err);
    return [];
  }
};

export const useIsUserPremium = (
  token: string,
  features: any,
  userData: User
) => {
  const [isPremiumUser, setIsPremiumUser] = useState(false);

  useEffect(() => {
    const calculateIsPremiumUser = async () => {
      const shouldUseOldAPIs = features?.newDashboardAccessConfig?.OldSubscriptionApis?.includes(
        'use'
      );

      if (shouldUseOldAPIs) {
        console.log('Using old APIs to check premium status');
        const isUserPremium = userData?.premium && userData?.premium?.isPremium;
        setIsPremiumUser(!!isUserPremium);
      } else {
        const userSubscriptions = await getUserSubscriptionsUsingAMApi(token);
        const activeSubscription = userSubscriptions?.find(
          subscription =>
            subscription.userId && subscription.status === 'Active'
        );

        console.log('Using new APIs to check premium status');
        setIsPremiumUser(!!activeSubscription);
      }
    };

    calculateIsPremiumUser();
  }, [
    features?.newDashboardAccessConfig?.OldSubscriptionApis,
    token,
    userData.premium
  ]);

  return isPremiumUser;
};

const ClassMissionsPlayView: React.FC<any> = () => {
  const token = useAppSelector((state: any) => state.login.token);
  const features = useAppSelector(state => state.featureFlag);
  const styles = useStyles();
  const userData = useSelector((state: any) => state.userData);
  const { currentClass } = useSelector((state: any) => state.class);
  const query = useQueryParams();
  const isPremiumUser = useIsUserPremium(token, features, userData);
  let isClassMissionTrialUser = false;

  useEffect(() => {
    if (query.goBack !== '1') {
      dispatch(trackNavBarClassMissionsItemClicked(classCode));
    }
    console.log(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [1]);

  const { flags } = React.useContext(TeacherFeatureFlagContext);
  if (
    (flags.classMissionTrialsExtended || flags.isMissionMonday) &&
    !isPremiumUser
  ) {
    isClassMissionTrialUser = true;
  }

  const classCode = currentClass.code;
  const dispatch = useDispatch();

  // const [
  //   shouldShowPlacementTestWarningModal,
  //   setShouldShowPlacementTestWarningModal
  // ] = useState(!hasUserSeenPlacementTestWarningModal());

  const handleShowHelpModal = () => {
    dispatch(
      show('modal', {
        type: MODAL_DEFAULT,
        title: 'About Class Missions',
        children: () => (
          <>
            <Typography>
              Here, your players can work together to answer questions and
              defeat a creature for rewards!
            </Typography>
            <br></br>
            <h3>Phases</h3>
            <br></br>
            <Typography>
              <b>Mission Setup: </b>
              Select your mission parameters. It is recommended to start screen
              sharing with your class before the mission begins.
            </Typography>
            <br></br>
            <Typography>
              <b>Waiting Room: </b>
              Project your screen and wait for your players to log into
              Dreamscape
            </Typography>
            <br></br>
            <Typography>
              <b>Mission In-Progress: </b>
              Launch the mission. Your players must answer enough questions
              within the time limit to win
            </Typography>
            <br></br>
            <Typography>
              <b>Results: </b>
              Your players are rewarded with in-game prizes for completing the
              mission
            </Typography>
          </>
        )
      })
    );
  };

  /**
   * @description creates class mission in education api and routes to class mission game
   */
  const createClassMission = () => {
    dispatch(
      push(
        userNavigateWithParam(
          userNavigateWithClassCode(teacherRoutes.classMissionsGame),
          'missionId',
          'new'
        )
      )
    );
  };

  // const hidePlacementTestWarningModal = () => {
  //   dispatch(hide('modal'));
  //   setPlacementTestWarningModalSeen();
  //   setShouldShowPlacementTestWarningModal(false);
  // };

  // const showPlacementTestWarningModal = () => {
  //   const placementTestLink = teacherRoutes.classReportsPlacementTest.replace(
  //     ':classCode',
  //     classCode
  //   );
  //   dispatch(
  //     show('modal', {
  //       type: MODAL_DEFAULT,
  //       title: 'Players in the placement test',
  //       dividers: true,
  //       showTitleClose: true,
  //       alignActions: 'center',
  //       hideCloseAction: true,
  //       leftActions: () => {
  //         return (
  //           <Button
  //             primary
  //             onClick={() => {
  //               hidePlacementTestWarningModal();
  //               startMission();
  //             }}
  //           >
  //             Continue to mission
  //           </Button>
  //         );
  //       },
  //       handleTitleClose: hidePlacementTestWarningModal,
  //       children: (
  //         <Grid container spacing={2}>
  //           <Grid item xs={12}>
  //             <p>
  //               Players need to complete their placement test to be able to
  //               participate in the mission. Visit the&nbsp;
  //               <Link
  //                 component={RouterLink}
  //                 to={placementTestLink}
  //                 style={{ color: colors.blueDark }}
  //                 onClick={hidePlacementTestWarningModal}
  //               >
  //                 Placement Test Report
  //               </Link>
  //               &nbsp;page to see if any players in your class still need to
  //               complete the placement test.
  //             </p>
  //           </Grid>
  //         </Grid>
  //       )
  //     })
  //   );
  // };

  const startMission = () => {
    dispatch(trackClassMissionLaunched(classCode));
    createClassMission();
  };

  const handleStartMission = () => {
    // if (shouldShowPlacementTestWarningModal) {
    //   showPlacementTestWarningModal();
    // } else {
    startMission();
    // }
  };

  const handleUpgradeToPremium = () => {
    dispatch(classMissionUpgradeButtonClicked(classCode));
    dispatch(
      push(userNavigateWithClassCode(teacherRoutes.teacherPremiumPlanClass))
    );
  };

  return (
    <>
      <Paper className={styles.paper}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '50%' }}>
            <Box display={'flex'} alignItems={'center'}>
              {!isPremiumUser && (
                <StarsIcon
                  fontSize={'large'}
                  style={{ fill: '#f8bb00', marginRight: '10px' }}
                />
              )}
              <Grid>
                <Typography variant="h5">
                  Class Missions: Fun and Focused
                </Typography>
              </Grid>
            </Box>
            <br></br>
            <p>Get involved by taking your players on Class Missions!</p>
            <br></br>
            <li>
              Watch your class team up to defeat the creature within the time
              limit
            </li>
            <li>
              The mission progresses as your players answer questions in
              Dreamscape normally
            </li>
            <li>
              Completing a mission rewards all players with in-game prizes
            </li>
            <br></br>
            <p>
              All you need to do is create a mission, share your screen with
              your class and cheer them on as they try to answer as many
              questions as they can before time runs out.
            </p>
          </div>

          <div
            style={{
              maxWidth: '50%',
              maxHeight: '300px',
              textAlign: 'right'
            }}
          >
            <Button
              onClick={handleShowHelpModal}
              style={{
                float: 'right',
                height: '24px',
                width: '24px',
                minWidth: '0'
              }}
            >
              <Help style={{ color: '#636363' }} />
            </Button>
            <img
              style={{
                maxWidth: '100%',
                height: '100%',
                objectFit: 'scale-down'
              }}
              src={screenshot}
              alt="screenshot"
            />
          </div>
        </div>

        <div className={isMobile ? styles.buttonMobile : styles.button}>
          {!isPremiumUser && isClassMissionTrialUser ? (
            <>
              {/* {flags.isMissionMonday && (
                <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                  <Typography component="span" variant="body2">
                    It’s <strong>Fun Friday!</strong> Enjoy a full day of Class
                    Missions for FREE.{' '}
                    <Typography
                      component="span"
                      style={{
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                        color: '#40d2d9',
                        cursor: 'pointer'
                      }}
                      variant="body2"
                      onClick={handleUpgradeToPremium}
                    >
                      Upgrade to Premium
                    </Typography>{' '}
                    for unlimited access all school year long.
                  </Typography>
                </Grid>
              )} */}
              <Button
                primary
                width={200}
                onClick={
                  flags.isMissionMonday
                    ? handleStartMission
                    : handleUpgradeToPremium
                }
              >
                {flags.isMissionMonday
                  ? 'Start a Mission'
                  : 'Upgrade to Premium'}
              </Button>
            </>
          ) : (
            <Grid container justify={'center'}>
              <Button
                primary
                width={200}
                onClick={
                  isPremiumUser ? handleStartMission : handleUpgradeToPremium
                }
              >
                {isPremiumUser ? 'Start a Mission' : 'Upgrade to Premium'}{' '}
              </Button>
            </Grid>
          )}
        </div>
      </Paper>
    </>
  );
};

export default ClassMissionsPlayView;
