import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './redux/store';
import routes, { Routes } from './router/routes';
import ModalSwitch from './components/popups/modals/ModalSwitch';
import Spinner from './components/spinner/Spinner';
import defaultTheme from './themes/default';
import SnackbarNotification from './components/snackbar/SnackbarNotification';
import ModalNotification from './components/popups/modals/ModalNotification';
import JobQueueNotification from './components/job-notification/JobQueueNotification';
import Chart from 'chart.js';
import ChartDataLabel from 'chartjs-plugin-datalabels';
import RouteGuard from './guards/route-guard/RouteGuard';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';
import { setFeatureFlag } from './redux/feature-flags/feature-flag-actions';
import { useAppSelector } from './redux/hooks';

// @ts-ignore
Chart.plugins.unregister(ChartDataLabel);

const App: React.FC = () => {
  const flags = useFlags();
  const ldClient = useLDClient();
  const dispatch = useDispatch();
  const userData = useAppSelector((state: any) => state.userData);
  const features = useAppSelector(state => state.featureFlag);
  /**
   * Updates launch darkly flag into react redux state
   */
  useEffect(() => {
    console.log('LaunchDarkly Flags:', { ...flags });
    if (flags.newLearningEngine && userData?.features?.userGeneratedContent) {
      userData.features.userGeneratedContent.hasAccess = true;
    }
    dispatch(
      setFeatureFlag({
        ...flags,
        ...userData.features,
        ldFlagsInitialised: !!Object.values(flags || {}).length
      })
    );
  }, [flags, dispatch, userData.features]);

  useEffect(() => {
    console.log('Refreshing LD flags for user ', userData.featureFlagTags);
    ldClient?.identify({
      key: 'db63f31b-1091-4838-ab04-59e006fcef9b',
      anonymous: true,
      custom: {
        id: userData._id,
        email: userData.email,
        username: userData.username,
        createdDate: userData.createdDate,
        featureFlagTags: userData.featureFlagTags,
        userType: userData.userType,
        ...userData.products,
        ...userData.premium
      }
    });
  }, [userData, ldClient]);

  return (
    <>
      <MuiThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <ConnectedRouter history={history}>
          <Spinner />
          <SnackbarNotification />
          <ModalNotification />
          <ModalSwitch />
          <JobQueueNotification />
          <Switch>
            {routes.map((route: Routes, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  render={() => {
                    if (
                      features?.oldDashboardAccessConfig?.restrictedUrls?.includes(
                        route.path
                      )
                    ) {
                      window.location.href = `${process.env.REACT_APP_NEW_DASHBOARD_URL}/login`;
                      return null;
                    } else {
                      return <RouteGuard {...route} />;
                    }
                  }}
                  exact
                />
              );
            })}
          </Switch>
        </ConnectedRouter>
      </MuiThemeProvider>
    </>
  );
};

export default App;
