import React, { useContext } from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { isMobile } from 'react-device-detect';
import { generatePath, withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, makeStyles, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import parentNav from './navbar-types/navbar-parent';
import teacherNav from './navbar-types/navbar-teacher';
import schoolAdminNav from './navbar-types/navbar-school-admin';
import districtAdminNav from './navbar-types/navbar-district-admin';
import premium_plan_card_banner from '../../assets/premium_plan_card.png';
import { teacherRoutes } from '../../constants/routes';
import {
  districtAdminMenu,
  parentMenu,
  schoolAdminMenu,
  teacherMenu
} from '../../redux/types/menu/menu-action-types';
import { SideBarActions, SidebarItems } from '../../types/sidebar/sidebar-type';
import { userLogout } from '../../redux/actions/user-actions';
import { push } from 'connected-react-router';
import { userTypes, userTypesDashboardRoute } from '../../constants/users';
import { borderRadius } from '../../themes/default';
import { useAppSelector } from '../../redux/hooks';
import { isBetaUser } from '../../utils/beta';
import { Features } from '../../feature-flag/Features';
import TeacherFeatureFlagContext from '../../layouts/teacher/TeacherFeatureFlagContext';

const useStyles = makeStyles(() => ({
  wrapper: {
    transition: '.3s'
  },
  menuItems: {
    overflowY: 'auto',
    overflowX: 'hidden',
    background: `
      /* Shadow covers */
        linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%
    `,
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'transparent',
    backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
    backgroundAttachment: 'local, local, scroll, scroll'
  }
}));

const NavItems: React.FC<any> = (props: any) => {
  let navItems: Array<SidebarItems>;
  // will render menu items based on available menu props
  const { match } = props;
  const classes = useStyles();
  const userData = useAppSelector(state => state.userData);
  const isPremiumUser = userData.premium && userData.premium.isPremium;
  const features = useAppSelector(state => state.featureFlag);
  const dispatch = useDispatch();
  const menuType = props.menuType;
  const { currentClass } = useSelector((state: any) => state.class);

  switch (props.menuType) {
    case parentMenu:
      navItems = parentNav(match.path);
      break;
    case teacherMenu:
      const { classCode } = props.match.params;
      navItems = teacherNav(match.path, classCode);
      // Send mixpanel event if the Class Missions are accessed
      /**
       * Handle user generated content
       */
      const hasUserGeneratedContentAccess =
        features.userGeneratedContent?.hasAccess || isBetaUser(userData.email);
      if (!hasUserGeneratedContentAccess) {
        navItems = navItems.filter(
          (navItem: SidebarItems) =>
            navItem.featureName !== Features.USER_GENERATED_CONTENT
        );
      }

      if (features.oldDashboardAccessConfig.allowedSidebarItems?.length) {
        navItems = navItems.filter(i =>
          features.oldDashboardAccessConfig.allowedSidebarItems?.includes(
            i.title
          )
        );
      }

      if (features.oldDashboardAccessConfig.hiddenSidebarItems?.length) {
        navItems = navItems.filter(
          i =>
            !features.oldDashboardAccessConfig.hiddenSidebarItems?.includes(
              i.title
            )
        );
      }

      break;
    case schoolAdminMenu:
      navItems = schoolAdminNav(match.path);
      break;
    case districtAdminMenu:
      navItems = districtAdminNav(match.path);
      break;
    default:
      navItems = [];
  }

  const generateAction = (actionType: SideBarActions) => {
    if (actionType === SideBarActions.Back) {
      // not working
      return props.history.back();
    }
    if (actionType === SideBarActions.Logout) {
      return dispatch(userLogout());
    }
    if (actionType === SideBarActions.MyAccount) {
      return dispatch(
        push(`${userTypesDashboardRoute[userData.userType]}/my-account`)
      );
    }
    if (actionType === SideBarActions.Support) {
      return window.open(process.env.REACT_APP_SUPPORT_LINK, '_blank');
    }
    return '';
  };

  const { code } = useSelector((state: any) => state.class.currentClass);
  const { _id: teacherId } = useSelector(
    (state: any) => state.admin.currentTeacher
  );
  const { _id: schoolId } = useSelector(
    (state: any) => state.admin.currentSchool
  );
  const paddingStyle = props.isDrawerOpen ? '0 15px' : '0 8px';
  // const showPromoBanner =
  //   !isPremiumUser &&
  //   props.isDrawerOpen &&
  //   menuType === teacherMenu &&
  //   !isMobile &&
  //   !props.match.path.includes('/dashboard/teacher/premium') &&
  //   !isMayPromotionActive;
  const showPromoBanner = false; // Completely disabling the promo banner
  return (
    //@ts-ignore
    <div style={{ padding: paddingStyle }} className={classes.wrapper}>
      <div
        className={classes.menuItems}
        style={{
          height:
            userData.userType === userTypes.parent || !showPromoBanner
              ? '100%'
              : 'calc(100vh - 410px - 64px)'
        }}
      >
        {navItems
          .filter(item => [undefined, true].includes(item.show))
          .map((item: any, i: number) => {
            let listItemProps: any;
            if (item.params && code) {
              listItemProps = {
                to: generatePath(item.to, {
                  classCode: code,
                  teacherId,
                  schoolId
                })
              };
            } else if (item.to) {
              if (typeof item.to === 'string') {
                listItemProps = {
                  to: item.to
                };
              } else {
                listItemProps = {
                  onClick: () => generateAction(item.to)
                };
              }
            } else if (item.onClick) {
              listItemProps = {
                onClick: item.onClick
              };
            }
            const listItem = (
              <ListItem
                key={i}
                button
                component={Link}
                disabled={item.disabled}
                selected={match.path === item.to}
                style={
                  isMobile
                    ? { borderRadius, margin: '10px 0', maxWidth: '210px' }
                    : { borderRadius, margin: '10px 0' }
                }
                {...listItemProps}
              >
                {item.beta || (item.badge && item.badge.active) ? (
                  <BadgeItem {...item} />
                ) : (
                  <>
                    <ListItemIcon>
                      <item.icon style={item.iconStyle} />
                    </ListItemIcon>
                    <ListItemText
                      style={{ whiteSpace: 'normal' }}
                      primary={item.title}
                    />
                  </>
                )}
              </ListItem>
            );
            if (props.isDrawerOpen) {
              return listItem;
            }
            return (
              <div>
                <Tooltip title={item.title} placement="right">
                  {listItem}
                </Tooltip>
              </div>
            );
          })}
      </div>
      {showPromoBanner && (
        <img
          src={premium_plan_card_banner}
          width="210"
          height="400"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            !props.match.path.includes(':classCode')
              ? dispatch(push(teacherRoutes.teacherPremiumPlan))
              : dispatch(
                  push(
                    teacherRoutes.teacherPremiumPlanClass.replace(
                      ':classCode',
                      currentClass.code as string
                    )
                  )
                );
          }}
        />
      )}
    </div>
  );
};

/**
 * @description NavItem that supports custom badges
 * @param item
 * @constructor
 */
function BadgeItem(item: SidebarItems) {
  const CustomBadgeComponent = item.badge?.component;
  if (item.badge && item.badge.active && CustomBadgeComponent) {
    let content;
    if (typeof item.badge.text === 'string') {
      content = item.badge.text;
    } else {
      const CustomElement = item.badge.text;
      content = <CustomElement />;
    }

    return (
      <CustomBadgeComponent
        override={item.badge.badgeOverride}
        anchorOrigin={item.badge.anchorOrigin}
        color={item.badge.color || 'primary'}
        badgeContent={content}
      >
        <ListItemIcon>
          <item.icon style={item.iconStyle} />
        </ListItemIcon>
        <ListItemText primary={item.title} />
      </CustomBadgeComponent>
    );
  }
  return (
    <Badge
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      color={'primary'}
      badgeContent={'Beta'}
    >
      <ListItemIcon>
        <item.icon style={item.iconStyle} />
      </ListItemIcon>
      <ListItemText primary={item.title} />
    </Badge>
  );
}

export default withRouter(NavItems);
